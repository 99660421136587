import React from "react";

export const Beer = ({menu}) => {
    return (<>
        <div className="row row-title">
            <div className="col-6 title">Назва товару</div>
            <div className="col-2 exit">Ціна<br /><small>(за 0.3)</small></div>
            <div className="col-2 price">Ціна<br /><small>(0.5)</small></div>
        </div>
        {menu.map(el => (
                <div className="row" key={el.id}>
                    <div className="col-6 title">
                        {el.title}
                        {el.description.length === 0 ? null : (<><br />
                            <small>({el.description})</small></>)}

                    </div>
                    <div className="col-2 exit">{parseFloat(el.price_0_3) === 0 ? "-" : parseFloat(el.price_0_3)}</div>
                    <div className="col-2 price">{parseFloat(el.price_0_5) === 0 ? "-" : parseFloat(el.price_0_5)}</div>
                </div>
            ))}
    </>)
}
import React, {useContext, useEffect} from "react";
import {CategoryContext} from "../../context/category/categoryContext";
import "./Menu.scss"
import {Logo} from "../../components/logo/Logo";
import {Eat} from "../../components/eat/Eat";
import {Beer} from "../../components/beer/Beer";
import {Wine} from "../../components/wine/Wine";
import {Alcohol} from "../../components/alcohol/Alcohol";
import {NoAlcohol} from "../../components/noalcohol/NoAlcohol";
import {Dishes} from "../../components/dishes/Dishes";
import {Coctails} from "../../components/coctails/Coctails";

export const Menu = ({match}) => {
    const {getCategory, menu, category, getMenu, sub_categories, getSubCategories} = useContext(CategoryContext)

    useEffect(() => {
        getCategory(match.params.id)
        getMenu(match.params.id)
        getSubCategories(match.params.id)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="menu">
            <div className="header">
                <div className="logo">
                    <Logo />
                </div>
                <h2 className="menu-header">{category.title}</h2>
            </div>
            <div className="content">
                {category.parent_id === 1 ? <Eat menu={menu} sub_categories={sub_categories} /> : null}
                {category.id === 11 ? <Beer menu={menu} /> : null}
                {[12,111].includes(category.id) ? <Wine menu={menu} sub_categories={sub_categories} /> : null}
                {[13,14,15,16].includes(category.id) ? <Alcohol menu={menu} sub_categories={sub_categories} /> : null}
                {[17,18].includes(category.id) ? <NoAlcohol menu={menu} /> : null}
                {[24].includes(category.id) ? <Coctails menu={menu} /> : null}
                {category.id === 19 ? <Dishes menu={menu} /> : null}
            </div>
        </div>
    )
}